<template>
  <div id="tm-footer" class="uk-section-small">
    <div class="uk-container uk-container-expand">
      <hr class="uk-margin-small" />
      <div
        class="uk-child-width-1-2@s uk-flex uk-flex-middle uk-text-small"
        uk-grid
      >
        <div>
          <div>{{ getYear() }} © PERAZNA. All Rights Reserved.</div>
        </div>
        <div class="uk-flex uk-flex-right@s">
          <ul class="uk-navbar-nav uk-text-small">
            <li>
              <a
                href="https://www.instagram.com/perazna/"
                target="_blank"
                rel="noopener noreferrer"
                >Instagram</a
              >
            </li>
            <li>
              <a
                href="https://twitter.com/perazna"
                target="_blank"
                rel="noopener noreferrer"
                >Twitter</a
              >
            </li>
            <li>
              <a
                href="https://opensea.io/PERAZNA"
                target="_blank"
                rel="noopener noreferrer"
              >
                NFTs
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="offcanvas" uk-offcanvas="mode: reveal; overlay: true">
      <div class="uk-offcanvas-bar uk-flex uk-flex-column">
        <button class="uk-offcanvas-close" type="button" uk-close></button>
        <ul class="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
          <li>
            <router-link to="/broken-heart">Broken Heart</router-link>
          </li>
          <li>
            <router-link to="/editorials">Editorials</router-link>
          </li>
          <li>
            <router-link to="/prints">Prints</router-link>
          </li>
          <li>
            <router-link to="/who-is-she">Who is SHE</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {};
  },
  methods: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
