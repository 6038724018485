<template>
  <NavigationBar />
  <router-view/>
  <FooterBar />
</template>
<script>
import NavigationBar from './components/NavBar.vue';
import FooterBar from './components/FooterBar.vue';

export default {
  name: 'App',
  components: {
    NavigationBar,
    FooterBar,
  },
  watch: {
    $route(to, from) {
      document.body.classList.remove(from.name);
      document.body.classList.add(to.name);
    },
  },
};

</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap");
html,
body {
  font-family: "Roboto", sans-serif;
  background-color: #fff9;
  color: #444;
}

#app {
  font-family: "Roboto", sans-serif;
  line-height: 26px;
  font-size: 17px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
.uk-heading-medium,
.uk-logo {
  font-family: "Libre Baskerville", serif;
}

.home .tm-header {
  position: fixed;
  width: 100%;
}

nav {
  padding: 30px;
}

nav a {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}
.uk-navbar-nav>li>a{
  color: #000;
  min-height: 30px;
  line-height: 30px;
}

nav a.router-link-exact-active, nav a:hover {
  color: #aa8208;
}
nav a:hover, #tm-footer ul li a:hover {
  color: #aa8208;
  text-decoration: underline;
}
.uk-button-primary{
  background-color: #aa8208;
  color: #fff;
}
.uk-button-primary:hover{
  background-color: #967206;
  color: #fff;
}
#tm-footer ul li a {
  color: #444;
}
.uk-offcanvas-reveal .uk-offcanvas-bar {
    background: #fff;
}
</style>
