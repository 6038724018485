<template>
  <div class="tm-header">
    <div class="uk-container uk-container-expand">
      <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar>
        <div class="uk-navbar-left">
          <router-link class="uk-navbar-item uk-logo" to="/"
            >PERAZNA</router-link
          >
        </div>
        <div class="uk-navbar-right">
          <div class="uk-navbar-item uk-visible@m">
            <ul class="uk-navbar-nav">
              <li>
                <a href="/portfolio">Portfolio</a>
                <div uk-dropdown="pos: top-right; offset: 10">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="uk-active">
                      <router-link to="/broken-heart">Broken Heart</router-link>
                    </li>
                    <li class="uk-active">
                      <router-link to="/editorials">Editorials</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <router-link to="/prints">Prints</router-link>
              </li>
              <li>
                <router-link to="/who-is-she">Who is SHE</router-link>
              </li>
            </ul>
          </div>
          <a
            class="uk-navbar-toggle uk-hidden@m"
            uk-navbar-toggle-icon
            uk-toggle="target: #offcanvas"
            href=""
          ></a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>
