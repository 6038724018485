<template>
  <div>
    <div
      class="uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-small uk-text-center"
      uk-grid
      uk-scrollspy="target: > div; cls: uk-animation-slide-bottom; delay: 200"
    >
      <div v-for="(image, index) in images" :key="index">
        <img :src="GetImage(image.id)" alt="" />
      </div>
    </div>
    <p class="uk-margin uk-text-center">
      <router-link to="/portfolio" class="uk-button uk-button-primary"
        >View all</router-link
      >
    </p>
  </div>
</template>

<script>
//import ImageCld from "@/components/ImageCld.vue";
import { Cloudinary } from "@cloudinary/url-gen";
import {scale} from "@cloudinary/url-gen/actions/resize";

export default {
  name: "HomeGrid",
  comments: {
    //AdvancedImage,
  },
  data() {
    return {
      // images: {
      //   // id and name
      //   0: { id: "homepage-best/h-grid-01_bzldot.jpg" },
      //   1: { id: "homepage-best/h-grid-06_dyddks.jpg" },
      //   2: { id: "homepage-best/h-grid-05_wsqbes.jpg" },
      //   3: { id: "homepage-best/h-grid-03_zghiwa.jpg" },
      //   4: { id: "homepage-best/h-grid-04_koehcu.jpg" },
      //   5: { id: "homepage-best/h-grid-02_v9il4m.jpg" },
      // },
    };
  },
  computed:{
      images(){
        let list = [];
        for (let i = 1; i <= 8; i++) {
          list.push({id: `broken/broken-0${i}.jpg`});
        }
        return list;
      }
    },
  methods: {
    // GetImage(id) {
    //   return cld.image(id);
    // },
    GetImage(id) {
      const cld = new Cloudinary({
          cloud: {
            cloudName: "dslv2c7op",
          },
        });
  
        const myImage = cld.image(id);
        const newImage = myImage.resize(scale().width(400).aspectRatio("2:3"));
        const myUrl = newImage.toURL();

      return myUrl;
    },
  },
};
</script>

<style lang="scss" scoped></style>
