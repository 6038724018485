import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/who-is-she',
    name: 'whoisshe',
    component: () => import(/* webpackChunkName: "whoisshe" */ '../views/SheView.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/PortfolioView.vue')
  },
  {
    path: '/prints',
    name: 'prints',
    component: () => import(/* webpackChunkName: "prints" */ '../views/PrintsView.vue')
  },
  {
    path: '/editorials',
    name: 'editorials',
    component: () => import(/* webpackChunkName: "editorials" */ '../views/EditorialsView.vue')
  },
  {
    path: '/broken-heart',
    name: 'brokenheart',
    component: () => import(/* webpackChunkName: "brokenheart" */ '../views/BrokenHeartView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
