<template>
  <div class="home-view">
    <div
      id="header"
      class="uk-background-cover uk-background-bottom-center uk-background-norepeat uk-overflow-hidden uk-height-viewport"
      uk-height-viewport="offset-bottom: 10; min-height: 500"
      style="
        background-color: #000;
        background-image: url('https://res.cloudinary.com/dslv2c7op/image/upload/v1673327033/homepage-best/h-header_x0ygwf.jpg');
      "
      uk-parallax="bgy: -300"
    ></div>
    <div class="content uk-section">
      <div class="uk-container">
        <h2 class="uk-text-center uk-heading-medium">Portfolio</h2>
        <HomeGrid />
      </div>
    </div>
  </div>
</template>

<script>
import HomeGrid from "@/components/HomeGrid.vue";

export default {
  name: "HomeView",
  components: {
    HomeGrid,
  },
};
</script>
<style scoped>

</style>
